<template>
  <b-modal
    id="ActionModal"
    ref="ActionModal"
    size="sm"
    centered
    hide-header
    hide-footer
  >
    <div class="row">
      <div class="col-7 pr-0" v-if="appointment">
        <div class>
          <p class="mb-1 mt-1">
            {{ appointment.extendedProps.treatmentPeriod }}
          </p>
          <hr class="my-1" />
          <div v-if="!isUnnamedPt">
            <p class="mb-0">DN: {{ appointment.extendedProps.dn }}</p>
            <p
              v-if="appointment.extendedProps.existDn"
              class="mb-1 small-text text-muted"
            >
              DN(เดิม): {{ appointment.extendedProps.existDn }}
            </p>
            <p
              v-if="appointment.extendedProps.patient.titleNameTh"
              class="mb-0"
            >
              {{ appointment.extendedProps.patient.titleNameTh }}
            </p>
          </div>

          <h6 class="narrow-spacing mb-0">
            <strong>
              {{ appointment.extendedProps.patient.firstNameTh }}
              <br />
              {{ appointment.extendedProps.patient.lastNameTh }}
            </strong>
          </h6>
          <div v-if="!isUnnamedPt">
            <p
              class="norrow-spacing mb-0"
              v-if="appointment.extendedProps.patient.dob"
            >
              อายุ:
              {{ calAge(appointment.extendedProps.patient.dob) }}
              ปี
            </p>
            <hr class="my-1" />
            <p class="mb-1 narrow-spacing">
              โทร: {{ appointment.extendedProps.phoneNo }}
            </p>
          </div>

          <hr class="my-1" />
          <p class="mb-1 narrow-spacing">
            แพทย์: {{ appointment.extendedProps.doctorName }}
          </p>
          <p class="mb-1 narrow-spacing">
            <strong v-html="appointment.extendedProps.treatmentTitle"></strong>
          </p>
          <p class="mb-1" v-if="appointment.extendedProps.note">
            โน๊ต: {{ appointment.extendedProps.note }}
          </p>
        </div>
      </div>
      <div class="col-5 d-flex justify-content-end">
        <b-button-group vertical class="align-self-stretch" v-if="isUnnamedPt">
          <b-button
            id="btnEditAppointment"
            class="btn btn-danger"
            :disabled="inClinic"
            @click="openEventModal"
          >
            <i class="fa fa-pencil-square" aria-hidden="true"></i>
            <br />แก้ไขนัด
          </b-button></b-button-group
        >
        <b-button-group vertical class="align-self-stretch" v-else-if="isMFD">
          <b-button id="btnMorFunDee" variant="primary" @click="openMFDtab">
            <img style="width: auto; height: 26px" :src="MorFunDeeLogo" />
            <br />
            Mor <br />
            Fun <br />Dee
          </b-button>
          <b-button
            id="btnCopyLink"
            variant="secondary"
            @click="copyToClipboard"
          >
            <i class="fas fa-copy"></i>
            <br />
            Copy Link
          </b-button>

          <b-button
            id="btnPatientDetail"
            class="btn btn-info"
            @click="openProfileCustomerModal"
          >
            <i class="fas fa-info"></i>
            <br />ประวัติ
          </b-button>

          <!-- <b-button
                id="btnEditAppointment"
                class="btn btn-danger"
                :disabled="btnDisable || inClinic()"
              > -->
          <b-button
            id="btnEditAppointment"
            class="btn btn-danger"
            :disabled="true"
            @click="openEventModal"
          >
            <i class="fa fa-pencil-square" aria-hidden="true"></i>
            <br />แก้ไข
          </b-button></b-button-group
        >
        <b-button-group
          vertical
          class="align-self-stretch"
          v-else-if="isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])"
        >
          <b-button
            id="btnCheckIn"
            class="btn btn-primary"
            v-if="!inClinic"
            :disabled="!isTodayApt || inClinic"
            @click="checkIn"
          >
            <i class="fa fa-user-md" aria-hidden="true"></i>
            <br />ส่งตรวจ
          </b-button>
          <b-button
            id="btnCancelCheckIn"
            class="btn btn-primary"
            v-if="inTreatmentQueueStatus"
            @click="cancelCheckIn"
          >
            <i class="fa fa-user-md" aria-hidden="true"></i>
            <br />ยกเลิก <br />ส่งตรวจ
          </b-button>
          <b-button
            id="btnPayment"
            class="btn btn-success"
            v-if="inPaymentQueueStatus"
            @click="openPaymentModal"
          >
            <i class="fas fa-cash-register"></i>
            <br />ชำระเงิน
          </b-button>
          <b-button
            id="btnPaymentHistory"
            class="btn btn-success"
            v-else
            :disabled="!isTodayApt || inPaidStatus"
            @click="openPaymentHistoryModal"
          >
            <i class="fas fa-notes-medical"></i>
            <br />บันทึก <br />รักษา
          </b-button>

          <b-button
            id="btnPatientDetail"
            class="btn btn-info"
            @click="openProfileCustomerModal"
          >
            <i class="fas fa-info"></i>
            <br />ประวัติ
          </b-button>
          <b-button
            id="btnEditAppointment"
            class="btn btn-danger"
            :disabled="inClinic"
            @click="openEventModal"
          >
            <i class="fa fa-pencil-square" aria-hidden="true"></i>
            <br />แก้ไขนัด
          </b-button>
        </b-button-group>
        <b-button-group vertical class="align-self-stretch" v-else>
          <b-button
            id="btnPatientDetail"
            class="btn btn-info"
            @click="openProfileCustomerModal"
          >
            <i class="fas fa-info"></i>
            <br />ประวัติ
          </b-button>
        </b-button-group>
      </div>
    </div>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

import "@/theme/QueueList.scss";
import MorFunDeeLogo from "../../assets/img/morfundee-logo.png";
import { eventBus } from "../../main";

export default {
  name: "ActionModal",
  components: {
    Dialog,
    Loading
  },
  data() {
    return {
      appointment: null,
      btnDisable: false,
      isTodayApt: true,
      isLoading: false,
      MorFunDeeLogo
    };
  },
  computed: {
    ...mapGetters({
      isAccessMenu: "moduleUser/getIsAccessMenu",
      branchLinkedWithNhso: "moduleUser/getLinkedWithNhso"
    }),
    // สถานะว่าคนไข้อยู่ในคลินิก คือ กำลังรอตรวจ รอชำระงเิน ชำระเงินเสร็จแล้ว
    inClinic() {
      if (this.appointment) {
        let statusId = this.appointment.extendedProps.statusId;
        return [5, 6, 7].includes(statusId) ? true : false;
      } else {
        return false;
      }
    },

    inTreatmentQueueStatus() {
      if (this.appointment) {
        return this.appointment.extendedProps.statusId == 5 ? true : false;
      } else {
        return false;
      }
    },
    inPaymentQueueStatus() {
      if (this.appointment) {
        return this.appointment.extendedProps.statusId == 6 ? true : false;
      } else {
        return false;
      }
    },
    inPaidStatus() {
      if (this.appointment) {
        return this.appointment.extendedProps.statusId == 7 ? true : false;
      } else {
        return false;
      }
    },
    isUnnamedPt() {
      return this.appointment?.extendedProps?.patient?.id === 1;
    },
    isMFD() {
      return this.appointment?.extendedProps?.linkedWithMFD;
    }
  },
  watch: {
    appointment() {
      this.isTodayApt = moment().isSame(this.appointment.start, "day");
    }
  },
  methods: {
    show(appointment) {
      this.appointment = appointment;
      this.$bvModal.show("ActionModal");
    },
    close() {
      this.$bvModal.hide("ActionModal");
    },
    async openPaymentHistoryModal() {
      if (this.appointment.extendedProps.statusId === 5) {
        this.$refs.Dialog.showAlertConfirm(
          'คนไข้อยู่ในสถานะ "รอเข้าตรวจ"',
          "คุณต้องการบันทึกการรักษาแทน แพทย์ ?"
        ).then(async res => {
          if (res.isConfirmed) {
            this.$parent.openPaymentHistoryModal(this.appointment);
            this.close();
          }
        });
      } else {
        if (this.branchLinkedWithNhso) {
          await this.$refs.Dialog.showAlertConfirm(
            "โครงการบัตรประชาชนใบเดียวฯ?",
            "บันทึกการรักษาคนไข้โครงการฯ",
            null,
            "โครงการบัตรประชาชนใบเดียวฯ",
            "คนไข้ชำระเอง",
            false
          )
            .then(async ({ isConfirmed }) => {
              const linkedWithNhso = isConfirmed;
              const p = /^\d{13}$/.test(
                this.appointment?.extendedProps?.patient?.personalId
              );

              if (p) {
                this.isLoading = true;

                const { data } = await this.$store.dispatch(
                  "moduleAppointment/confirmQueueCheckIn",
                  {
                    event: {
                      linkedWithNhso,
                      roomId: this.appointment.extendedProps.roomId,
                      doctorUid: this.appointment.extendedProps.doctor,
                      aptTreatItems:
                        this.appointment.extendedProps.treatmentList
                    },
                    id: this.appointment.id,
                    clinicUrl: this.$route.params.clinicUrl,
                    branchUrl: this.$route.params.branchUrl
                  }
                );
                this.$parent.openPaymentHistoryModal(data);
                this.close();
              } else {
                this.$refs.Dialog.showAlertInfo(
                  "ไม่สามารถ Check-in ได้เนื่องจากบัตรประชาชนไม่ถูกต้อง"
                );
              }
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.$parent.openPaymentHistoryModal(this.appointment);
          this.close();
        }
      }
    },
    openProfileCustomerModal() {
      this.$parent.openProfileCustomerModal(
        this.appointment,
        this.appointment.extendedProps.patient,
        false,
        "createAptOnly"
      );
      this.close();
    },
    openEventModal() {
      this.$parent.openEventModal(
        this.appointment,
        this.appointment.extendedProps.patient
      );
      this.close();
    },
    openPaymentModal() {
      this.$parent.openPaymentModal(this.appointment);
      this.close();
    },
    checkIn() {
      let indfEvent = this.$parent.appointmentList.findIndex(
        i => i.id === parseInt(this.appointment.id)
      );
      this.$parent.openCheckInModal(indfEvent);
      this.close();
    },
    cancelCheckIn() {
      let indfEvent = this.$parent.appointmentList.findIndex(
        i => i.id === parseInt(this.appointment.id)
      );
      this.$parent.cancelCheckIn(indfEvent);
      this.close();
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(
          `${this.lastFcAppointmentSelected.extendedProps.mfdClipboradText}`
        );
        eventBus.$emit("alertToast", {
          message: "บันทึกใส่คลิปบอร์ดสำเร็จ",
          variant: "success"
        });
      } catch (error) {
        console.error(error);
        eventBus.$emit("alertToast", {
          message: "บันทึกใส่คลิปบอร์ดไม่สำเร็จ",
          variant: "warning"
        });
      }
    },
    async openMFDtab() {
      window
        .open(
          this.appointment.extendedProps.mfdAppointmentMeetingUrl +
            "&mode=dentist",
          "_blank"
        )
        .focus();
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    }
  }
};
</script>

<style>
</style>