<template>
  <div class="content-wrapper view-queue-list pt-3">
    <div class="content">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" xl="6">
              <b-form-group
                label-cols="2"
                label-align="right"
                label="ตารางนัดวันที่: "
              >
                <b-row class="mx-0">
                  <datetime
                    class="col px-0 mr-2"
                    type="date"
                    :value="date"
                    input-class="form-control"
                    format="cccc dd LLLL yyyy"
                    value-zone="UTC+7"
                    :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
                    :week-start="7"
                    auto
                    @input="formatDate"
                  ></datetime>

                  <b-button
                    class="mr-2"
                    variant="outline-primary"
                    :pressed="isSameDate(0)"
                    @click="today"
                    >วันนี้</b-button
                  >
                  <b-button
                    class="mr-2"
                    variant="outline-info"
                    :pressed="isSameDate(1)"
                    @click="tomorrow"
                    >พรุ่งนี้</b-button
                  >
                  <b-button-group class>
                    <b-button variant="primary" @click="prevDay">
                      <i class="fas fa-chevron-left"></i>
                    </b-button>
                    <b-button variant="primary" @click="nextDay">
                      <i class="fas fa-chevron-right"></i>
                    </b-button>
                  </b-button-group>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6">
              <b-form-group
                label-cols="2"
                label-align="right"
                label-class
                label="ค้นหานัด: "
                description="ค้นหาเฉพาะวันที่เลือก"
              >
                <b-row>
                  <b-input-group class="col">
                    <b-input
                      class
                      type="search"
                      v-model="filter"
                      placeholder="พิมพ์ค้นหา DN. ชื่อค้นไข้ หรือเบอร์โทร"
                    ></b-input>
                    <template #append>
                      <b-button class variant="danger" @click="defaultValue">
                        <i class="fas fa-times"></i>
                      </b-button>
                    </template>
                  </b-input-group>
                  <b-button class="mr-3" @click="generatePdf('queueListTable')">
                    <i class="fas fa-file-export"></i>
                  </b-button>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- <b-col cols="2" xl="1"> </b-col> -->
          </b-row>
          <b-row>
            <b-col offset="2" offset-xl="1" cols="10" md class="mb-3">
              <b-form-select
                v-model="filterDoctor"
                :options="doctorList"
                value-field="uid"
                text-field="fullName"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >-- เลือกแพทย์ --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
            <b-col offset="2" offset-md="0" cols="10" md class="mb-3">
              <b-form-select
                v-model="filterRoom"
                :options="roomList"
                value-field="id"
                text-field="title"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >-- เลือกห้อง --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
            <b-col offset="2" offset-md="0" cols="10" md class="mb-3">
              <b-form-select v-model="filterReminder">
                <b-form-select-option :value="null"
                  >-- เลือกแจ้งเตือน --</b-form-select-option
                >
                <b-form-select-option value="incompleted"
                  >แจ้งเตือนงาน(ยังไม่เสร็จ)</b-form-select-option
                >
                <b-form-select-option value="completed"
                  >แจ้งเตือนงาน(เสร็จแล้ว)</b-form-select-option
                >
              </b-form-select>
            </b-col>
            <b-col offset="2" offset-md="0" cols="10" md class="mb-3">
              <b-form-select
                v-model="filterStatus"
                :options="getAppointmentStatusList"
                value-field="code"
                text-field="label"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >-- เลือกสถานะ --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
          </b-row>
          <hr />
          <!-- {{ appointmentList }} -->
          <b-table
            ref="queueListTable"
            class
            small
            hover
            head-variant="light"
            sort-icon-left
            :items="filteredAppointmentList"
            :fields="fields"
            :filter="filter"
            sort-by="extendedProps.treatmentPeriod"
            @row-clicked="openActionModal"
            responsive
          >
            <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
            <template #cell(dn)="row">
              <span class="narrow-spacing">
                {{ row.item.extendedProps.dn }}
                <span class="small-text text-muted">
                  {{
                    row.item.extendedProps.existDn
                      ? "/" + row.item.extendedProps.existDn
                      : ""
                  }}
                </span>
              </span>
            </template>
            <template v-slot:cell(treatmentTitle)="row">
              <span
                class="narrow-spacing"
                v-html="row.item.extendedProps.treatmentTitle"
              ></span>
            </template>
            <template #cell(doctorId)="row">
              <div>{{ row.item.extendedProps.doctorName }}</div>
            </template>
            <template v-slot:cell(resourceId)="row">
              <span>{{ getRoomListBy(row.item.resourceId, "id").name }}</span>
            </template>
            <template #cell(rmdId)="row">
              <div v-if="row.value">
                <span v-if="printing">
                  {{ findReminderObj(row.value, "val").label }}
                </span>
                <b-button
                  v-else
                  class="border-0"
                  block
                  :style="{
                    'background-color': findReminderObj(row.value, 'val').desc,
                  }"
                  size="sm"
                  @click="openReminderModal(row.item)"
                  >{{ findReminderObj(row.value, "val").label }}</b-button
                >
              </div>
            </template>
            <template v-slot:cell(statusId)="row">
              <span v-if="printing"
                >{{ findStatusName(row.item.extendedProps.statusId).label }}
              </span>
              <b-button
                v-else
                class="border-0"
                block
                :style="{
                  'background-color': row.item.extendedProps.statusColor,
                }"
                size="sm"
                @click="openStatusModal(row.item)"
                :disabled="
                  inClinic(row.item) ||
                  !isAccessMenu([
                    'COUNTER',
                    'MANAGER',
                    'ASSISTANT',
                    'HEADCOUNTER',
                  ])
                "
                >{{
                  findStatusName(row.item.extendedProps.statusId).label
                }}</b-button
              >
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <CustomerModal
      ref="customerModal"
      @openProfileCustomerModal="openProfileCustomerModal"
    ></CustomerModal>
    <SearchCustomerModal
      ref="searchCustomerModal"
      @openProfileCustomerModal="openProfileCustomerModal"
      @openCustomerModal="openCustomerModal"
    ></SearchCustomerModal>
    <ProfileCustomerModal
      ref="profileCustomerModal"
      @openCustomerModal="openCustomerModal"
      @openEventModal="openEventModal"
    ></ProfileCustomerModal>
    <PaymentHistoryModal ref="paymentHistoryModal"></PaymentHistoryModal>
    <EventModal ref="eventModal" @goToDateTime="goToDateTime"></EventModal>
    <CheckinModal
      ref="checkinModal"
      @openCustomerModal="openCustomerModal"
    ></CheckinModal>
    <PaymentModal ref="paymentModal"></PaymentModal>
    <!-- <EmrModal ref="emrModal"></EmrModal> -->
    <ActionModal ref="actionModal"></ActionModal>
    <StatusModal ref="statusModal" @isLoading="isLoading = $event" />
    <ReminderModal ref="reminderModal" @isLoading="isLoading = $event" />
    <PdfExport ref="pdfExport" />

    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>

    <Notification></Notification>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

import CustomerModal from "@/components/modal/Customer";
import SearchCustomerModal from "@/components/modal/SearchCustomer";
import ProfileCustomerModal from "@/components/modal/ProfileCustomer";
import PaymentHistoryModal from "@/components/modal/PaymentHistory";
import EventModal from "@/components/modal/Event";
import CheckinModal from "@/components/modal/Checkin";
import PaymentModal from "@/components/modal/Payment";
// import EmrModal from "@/components/modal/Emr";
import ActionModal from "@/components/modal/Action";
import StatusModal from "@/components/modal/Status";
import ReminderModal from "@/components/modal/Reminder";
import PdfExport from "@/components/PdfExport";

import Dialog from "@/components/modal/Dialog";
import Notification from "@/components/Notification";
import Loading from "@/components/Loading";

import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  name: "AppointmentList",
  components: {
    CustomerModal,
    SearchCustomerModal,
    ProfileCustomerModal,
    PaymentHistoryModal,
    EventModal,
    CheckinModal,
    StatusModal,
    Dialog,
    Notification,
    Loading,
    PaymentModal,
    // EmrModal,
    ActionModal,
    ReminderModal,
    PdfExport,
  },
  data() {
    return {
      isLoading: false,
      date: moment().format("YYYY-MM-DD"),
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "extendedProps.treatmentPeriod",
          label: "เวลา",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "dn",
          label: "DN./DN.(เดิม)",
          class: "narrow-spacing",
          // sortable: true
        },
        {
          key: "extendedProps.patientName",
          label: "ชื่อคนไข้",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "extendedProps.patient.dob",
          label: "อายุ",
          class: "narrow-spacing",
          formatter: "calAge",
        },
        {
          key: "doctorId",
          label: "แพทย์",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "resourceId",
          label: "ห้อง",
          sortable: true,
        },
        {
          key: "treatmentTitle",
          label: "นัดเพื่อ",
          class: "narrow-spacing",
          // sortable: true
        },
        {
          key: "extendedProps.note",
          label: "โน๊ตเพิ่มเติม",
          class: "narrow-spacing",
        },
        {
          key: "extendedProps.phoneNo",
          label: "เบอร์โทร",
          class: "narrow-spacing",
        },
        {
          key: "rmdId",
          label: "แจ้งเตือน",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "statusId",
          label: "สถานะ",
          sortable: true,
          class: "narrow-spacing",

          // class: "p-0 m-0",
        },
      ],
      filter: null,
      filterDoctor: null,
      filterRoom: null,
      filterStatus: null,
      filterReminder: null,
      isReadyWatch: false,
      fromTodo: false,
      printing: false,
    };
  },
  computed: {
    ...mapGetters({
      roomList: "moduleUser/getRoomList",
      doctorList: "moduleUser/getDoctorList",
      getRoomListBy: "moduleUser/getRoomListBy",
      branchInfo: "moduleUser/getBranchInfo",
      appointmentList: "moduleAppointment/getQueueAppointmentList",
      appointmentBy: "moduleAppointment/getQueueAppointmentBy",
      getAppointmentStatusList: "moduleMaster/getAppointmentStatusList",
      getReminderList: "moduleMaster/getReminderList",

      isAccessMenu: "moduleUser/getIsAccessMenu",
    }),
    dropdownStatus() {
      return this.getAppointmentStatusList.filter(
        (item) => item.code < 5 || item.code > 7
      );
    },
    filteredAppointmentList() {
      let filteredList = this.appointmentList
        .filter((i) => i.extendedProps.patient?.id) //filter note ออก
        .map((i) => {
          let o = Object.assign({}, i);
          o.statusId = i.extendedProps.statusId;
          o.rmdId = i.extendedProps.rmdId;
          o.doctorId = i.extendedProps.doctor;
          return o;
        });
      if (this.filterDoctor) {
        filteredList = filteredList.filter(
          (apt) => apt.extendedProps.doctor == this.filterDoctor
        );
      }
      if (this.filterRoom) {
        filteredList = filteredList.filter(
          (apt) => apt.resourceId == this.filterRoom
        );
      }
      if (this.filterStatus) {
        filteredList = filteredList.filter(
          (apt) => apt.statusId == this.filterStatus
        );
      }
      if (this.filterReminder) {
        if (this.filterReminder === "incompleted") {
          filteredList = filteredList.filter(
            (apt) => apt.rmdId > 0 && apt.rmdId <= 10
          );
        } else if (this.filterReminder === "completed") {
          filteredList = filteredList.filter((apt) => apt.rmdId > 10);
        }
      }

      return filteredList;
    },
    isEmptyAptList() {
      return this.appointmentList.length === 0 ? true : false;
    },
  },
  created() {
    this.init();
  },
  watch: {
    date: function (newDate) {
      if (this.isReadyWatch) {
        this.setStateQueueDate(newDate);
        this.getAppointmentListByDate(newDate);
      }
    },
  },
  methods: {
    ...mapActions({
      fetchQueueAppointmentList: "moduleAppointment/fetchQueueAppointmentList",
      moveQueueAppointment: "moduleAppointment/moveQueueAppointment",
      setStateQueueDate: "moduleAppointment/setStateQueueDate",
      confirmationQueueAppointment:
        "moduleAppointment/confirmationQueueAppointment",
      addRecentPatient: "modulePatient/addRecentPatient",
    }),
    init() {
      this.setStateQueueDate(this.date);
      this.getAppointmentListByDate(this.date);
      this.isReadyWatch = true;
    },
    getAppointmentListByDate(date, silent = false) {
      if (!silent) this.isLoading = true;
      if (!date) date = moment().format("YYYY-MM-DD");
      this.fetchQueueAppointmentList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        date: date,
      })
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    today(e) {
      this.date = moment().format("YYYY-MM-DD");
    },
    tomorrow() {
      this.date = moment().add(1, "day").format("YYYY-MM-DD");
    },
    nextDay() {
      this.date = moment(this.date).add(1, "days").format("YYYY-MM-DD");
    },
    prevDay() {
      this.date = moment(this.date).add(-1, "days").format("YYYY-MM-DD");
    },
    changeApptStatus(apt, statusId) {
      this.isLoading = true;
      this.confirmationQueueAppointment({
        statusId: { statusId: statusId },
        id: apt.id,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then((res) => {
          if (res.status == "200") {
            var index = this.appointmentList.findIndex(function (event) {
              return event.id == res.data.id;
            });
            if (index >= 0) {
              Object.assign(this.appointmentList[index], res.data);
            }
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$parent.showDialogToast("warning", "ไม่สามารถเปลี่ยนสถานะได้");
        });
    },
    findStatusName(statusId) {
      return this.getAppointmentStatusList.find(function (item) {
        return item["code"] == statusId;
      });
    },
    findReminderObj(rmdId) {
      return this.getReminderList.find(function (item) {
        return item["val"] == rmdId;
      });
    },

    //open Modal components
    openCustomerModal(patient, appointment = null, readOnly) {
      if (!appointment && this.lastFcResourceSelected) {
        appointment = this.getAppointmentFormat(this.lastFcResourceSelected);
      }
      this.$nextTick().then((res) => {
        if (this.$refs.customerModal) {
          this.$refs.customerModal.show(patient, appointment, readOnly);
        }
      });
    },
    openSearchCustomerModal() {
      const appointment = this.getAppointmentFormat(
        this.lastFcResourceSelected
      );
      this.$nextTick().then((res) => {
        if (this.$refs.searchCustomerModal) {
          this.$refs.searchCustomerModal.show(appointment);
        }
      });
    },
    openProfileCustomerModal(dataToEvent, patient, hideFooter, mode) {
      this.$refs.profileCustomerModal.show(
        dataToEvent,
        patient,
        hideFooter,
        mode
      );
    },
    openPaymentHistoryModal(appointment, patient) {
      this.$refs.paymentHistoryModal.show(appointment, patient);
    },
    openEventModal(dataToEvent, patient) {
      this.$refs.eventModal.show(dataToEvent, patient);
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show();
    },
    // openEmrModal(patient, drawObj, readOnly) {
    //   this.$refs.emrModal.show(patient, drawObj, readOnly);
    // },
    openNextVisitModal(patient) {
      this.$refs.nextVisitModal.show(patient);
    },
    openPaymentModal(data, index) {
      this.$refs.paymentModal.show(data);
    },
    openActionModal(item, index) {
      this.addRecentPatient(item.extendedProps.patient);
      this.$refs.actionModal.show(item);
    },
    openCheckInModal(index, appointment, patient) {
      this.$refs.checkinModal.show(index, appointment, patient);
    },
    openStatusModal(appointment) {
      this.$refs.statusModal.show(appointment);
    },
    openReminderModal(appointment) {
      this.$refs.reminderModal.show(appointment);
    },
    cancelCheckIn(index) {
      this.$refs.Dialog.showAlertConfirm(
        "ยกเลิกส่งตรวจ ?",
        "คุณต้องการยกเลิกส่งตรวจ",
        "warning",
        "ใช่",
        "ไม่"
      ).then((res) => {
        if (res.isConfirmed) {
          this.isLoading = true;
          this.$refs.checkinModal
            .cancelCheckin(index)
            .then(() => {
              this.showDialogToast("success", "ยกเลิกส่งตรวจสำเร็จ");
            })
            .catch(() => {
              this.showDialogToast("warning", "ยกเลิกส่งตรวจไม่สำเร็จ");
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },

    //Utils
    showDialogToast(variant, textDetail, noAutoHide) {
      this.$refs.Dialog.showToast(variant, textDetail, noAutoHide);
    },
    goToDateTime(dateTime) {
      let date = moment(dateTime).format("YYYY-MM-DD");
      // let time = moment(dateTime).format("HH:mm:ss");
      this.date = date;
    },
    inClinic(appointment) {
      if (appointment) {
        return appointment.extendedProps.statusId == 5 ||
          appointment.extendedProps.statusId == 6 ||
          appointment.extendedProps.statusId == 7
          ? true
          : false;
      } else {
        return false;
      }
    },
    isSameDate(dayAdd) {
      return moment(this.date).isSame(moment().add(dayAdd, "day"), "day");
    },
    async generatePdf(el) {
      this.printing = true;
      await this.$nextTick();
      await this.$refs.pdfExport.generatePdf({
        header: this.$refs.header,
        body: this.$refs[el].$el,
        footer: this.$refs.footer,
        option: {
          orientation: "landscape",
          // fontSize: "10px",
        },
      });
      this.printing = false;
    },
    defaultValue() {
      this.filter = null;
      this.filterDoctor = null;
      this.filterRoom = null;
      this.filterStatus = null;
      this.filterReminder = null;
    },
    async createEmptyTreatment(appointment) {
      let treatment = {
        // id: null,
        appoinmentId: appointment.id,
        items: [],
      };
      await this.$store
        .dispatch("moduleTreatment/createTreatment", {
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          event: treatment,
        })
        .then((res) => {
          // if (res.status == 200) {
          // }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    formatDate(val) {
      this.date = moment(val).format("YYYY-MM-DD");
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
  mounted() {
    eventBus.$on("openCustomerModal", () => {
      this.$refs.customerModal.show();
    });
    eventBus.$on("openSearchCustomerModal", () => {
      this.$refs.searchCustomerModal.show();
    });
    eventBus.$on("openCheckInModal", (appointment) => {
      this.openCheckInModal(null, appointment);
    });
    eventBus.$on("openPaymentHistoryModal", (appointment) => {
      this.openPaymentHistoryModal(appointment);
    });
    eventBus.$on("openPaymentModal", async (appointment) => {
      await this.createEmptyTreatment(appointment);
      this.openPaymentModal(appointment);
    });
    eventBus.$on("todoClick", (arg) => {
      this.fromTodo = true;
      this.defaultValue();
      this.tomorrow();
      // if (arg === "status") {
      //   this.filterStatus = 1;
      // } else if (arg === "reminder") {
      //   this.filterReminder = "incompleted";
      // }
    });
    if (!this.fromTodo) {
      this.today();
    }
    eventBus.$on("fetch-queue-data", async () => {
      await this.$nextTick();
      this.getAppointmentListByDate(this.date, true);
    });
  },

  updated() {},
  destroyed() {
    eventBus.$off("openCustomerModal");
    eventBus.$off("openSearchCustomerModal");
    eventBus.$off("openCheckInModal");
    eventBus.$off("openPaymentHistoryModal");
    eventBus.$off("openPaymentModal");
    eventBus.$off("todoClick");
    eventBus.$off("fetch-queue-data");
  },
};
</script>

<style>
</style>